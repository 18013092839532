import './css/main.css';

import Plyr from 'plyr';

import barba from '@barba/core';
import { gsap } from "gsap";

var Isotope = require('isotope-layout');
import 'isotope-packery';

var SmoothScroll = require('smooth-scroll');

var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 600,
    speedAsDuration: true
});

barba.hooks.beforeLeave((data) => {
  var scrolltop = new SmoothScroll();
  scrolltop.animateScroll(0, {
    speed: 500,
    speedAsDuration: true
  });
  
});

window.barba = barba;

barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
      {
      name: 'opacity-transition',
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0
        });
      },
      enter(data) {
        
      },
      after(data) {
        return gsap.from(data.next.container, {
            opacity: 0
          });
        
        
      },
      once(data) {
        customPlayer();
        setHighlights(data);
        autoOpenSubMenus(data);
        isoOpen(data);
      }
    }],
});

function customPlayer() {
    var players = document.getElementsByClassName("customPlayer");
    if (players.length > 0) {
        const player = new Plyr('#player', {
            controls: [
            // 'play-large', // The large play button in the center
            // 'restart', // Restart playback
            //       'rewind', // Rewind by the seek time (default 10 seconds)
                'play', // Play/pause playback
            //       'fast-forward', // Fast forward by the seek time (default 10 seconds)
                'progress', // The progress bar and scrubber for playback and buffering
                'current-time', // The current time of playback
                'duration', // The full duration of the media
                'mute', // Toggle mute
                'volume', // Volume control
                'captions', // Toggle captions
            //       'settings', // Settings menu
                'pip', // Picture-in-picture (currently Safari only)
                'airplay', // Airplay (currently Safari only)
            //       'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
                'fullscreen' // Toggle fullscreen
            ],
            autoplay: false,
            clickToPlay: true,
            fullscreen: { enabled: true, fallback: true, iosNative: true }
        });
    };
};

function openDropdown(dropdown, preset) {
    // let menuElement = document.getElementById(dropdown);
    // var status = menuElement.getAttribute("data-status");
    // if (typeof preset !== 'undefined') {
    //     var status = preset;
    // } else false
    // if (status === "c") {
    //     menuElement.classList.remove("hidden");
    //     setTimeout(function(){menuElement.style.maxHeight = "200px";},100);
    //     setTimeout(function(){menuElement.classList.remove("opacity-0")}, 500);
    //     menuElement.setAttribute("data-status", "o");
    // } else {
    //     menuElement.classList.add("opacity-0");
    //     setTimeout(function(){menuElement.style.maxHeight = "0px"}, 500);
    //     setTimeout(function(){menuElement.classList.add("hidden");}, 1000);
    //     menuElement.setAttribute("data-status", "c");
    // }
}

let projectsMenuItem = document.getElementById("projectsWholeMenu");
projectsMenuItem.addEventListener("mouseover", function(event) {
    openDropdown("projectSubmenu", "c");
});

projectsMenuItem.addEventListener("mouseleave", function(event) {
    let mouseoutprojects = setTimeout(function () {openDropdown("projectSubmenu", "o")}, 1500);
    projectsMenuItem.addEventListener("mouseover", function(event) {clearTimeout(mouseoutprojects)});
});


let storeMenu = document.getElementById("storeWholeMenu");
storeMenu.addEventListener("mouseover", function(event) {
    openDropdown("storeSubmenu", "c");
});

storeMenu.addEventListener("mouseleave", function(event) {
    let mouseoutstore = setTimeout(function () {openDropdown("storeSubmenu", "o")}, 1500);
    storeMenu.addEventListener("mouseover", function(event) {clearTimeout(mouseoutstore)});
});



// ------------------ Menu Highlights ------------------

function resetHighlights() {
    let highlights = document.getElementsByClassName("navHighlight");
    let i;
    for (i=0; i < highlights.length; i++) {
        highlights[i].classList.remove("text-samHighlight");
    };
    document.getElementById("cartButton").classList.add("opacity-0");
    document.getElementById("cartButton").classList.add("pointer-events-none");
};

function setHighlights (data) {
    if (data.next.namespace === "projects" || data.next.namespace === "home") {
        resetHighlights();
        setTimeout(function(){document.getElementById("projectsMenuItem").classList.add("text-samHighlight")});
        // let catContainer = document.getElementById("catContainer");
        // if (typeof(catContainer) !== 'undefined' && catContainer!== null ) {
        //     let category = catContainer.getAttribute("data-cat");
        //     let catButton = document.getElementById("catButton-" + category);
        //     catButton.classList.add("text-samHighlight");
        // }
    
    } else if (data.next.namespace === "about") {
        resetHighlights();
        setTimeout(function(){document.getElementById("aboutMenuItem").classList.add("text-samHighlight")});
    } else if (data.next.namespace === "store") {
        resetHighlights();
        setTimeout(function(){document.getElementById("storeMenuItem").classList.add("text-samHighlight")});
        document.getElementById("cartButton").classList.remove("opacity-0");
        document.getElementById("cartButton").classList.remove("pointer-events-none");
        // let catContainer = document.getElementById("catContainer");
        // if (typeof(catContainer) !== 'undefined' && catContainer!== null ) {
        //     let category = catContainer.getAttribute("data-cat");
        //     console.log(category);
        //     let catButton = document.getElementById("catButtonStore-" + category);
        //     catButton.classList.add("text-samHighlight");
        // }
    } else {
        resetHighlights();
    }
}

function autoOpenSubMenus(data) {
    if (data.next.namespace === "projects") {
        // openDropdown("projectSubmenu", "c");
        // openDropdown("storeSubmenu", "o");
    } else if (data.next.namespace === "store") {
        // openDropdown("storeSubmenu", "c");
        // openDropdown("projectSubmenu", "o");
    } else {
        // openDropdown("projectSubmenu", "o");
        // openDropdown("storeSubmenu", "o");
    };
}


// ------------------------------  Isotope ------------------------------


function isoOpen(data) {
    console.log("isoOpen()");
    var isogrid = document.getElementsByClassName("iso-grid");
    if ( isogrid.length > 0 ) {
    var iso = new Isotope( '.iso-grid', {
        itemSelector: '.grid-item',
        stamp: '.stamp',
        hiddenStyle: {
          opacity: 0
        },
        layoutMode: 'packery',
        percentPosition: true,
        // masonry: {
        //     columnWidth: '.grid-sizer',
        // },
        visibleStyle: {
          opacity: 1
        },
      });
  
      function showItems () {
          var grid = document.getElementsByClassName("iso-grid");
          var i;
          for (i = 0; i < grid.length; i++) {
              grid[i].style.opacity = 1;
          };
      };
  
      showItems();
  
      document.fonts.ready.then(function () {
        console.log("Fonts Loaded");
        iso.layout();
      });
  
  
  
      imageHeightFix();

      barba.hooks.afterOnce((data) => {
        console.log("Second ISO Layout");
        iso.layout();
        });

      barba.hooks.afterLeave((data) => {
        console.log("ISO DESTROY");
        iso.destroy();
        });
    
    }

};
  
      



// ------------------------------  Image Height Fix ------------------------------


function imageHeightFix() {

    if (CSS.supports("aspect-ratio: 1 / 1")) {
  
  
        // Should only need to add this to the 'else' part of the if statement. This is a temporary fix for a safari bug.
  
        var images = document.getElementsByClassName("lazyload");
        var i;
        for (i = 0; i < images.length; i++) {
            var imgwidth = images[i].getAttribute("width");
            var imgheight = images[i].getAttribute("height");
  
  
  
            if (images[i].parentElement.classList.contains("imagebackground")) {
                var containerwidth = images[i].parentElement.clientWidth;
                var newheight = (imgheight / imgwidth) * containerwidth;
                images[i].parentElement.style.height = newheight + 'px';
            } else {
                var containerwidth = images[i].clientWidth;
                var newheight = (imgheight / imgwidth) * containerwidth;
                images[i].style.height = newheight + 'px';
            };
  
            // console.log(newheight);
  
        };
  
        document.addEventListener('lazyloaded', function (e) {
            if (e.target.parentElement.classList.contains("imagebackground")) {
                e.target.parentElement.style.height = 'auto';
            } else {
                e.target.style.height = 'auto';
            };
        });
  
    } else {
        var images = document.getElementsByClassName("lazyload");
        var i;
        for (i = 0; i < images.length; i++) {
            var imgwidth = images[i].getAttribute("width");
            var imgheight = images[i].getAttribute("height");
  
  
  
            if (images[i].parentElement.classList.contains("imagebackground")) {
                var containerwidth = images[i].parentElement.clientWidth;
                var newheight = (imgheight / imgwidth) * containerwidth;
                images[i].parentElement.style.height = newheight + 'px';
            } else {
                var containerwidth = images[i].clientWidth;
                var newheight = (imgheight / imgwidth) * containerwidth;
                images[i].style.height = newheight + 'px';
            };
  
            // console.log(newheight);
  
        };
  
        document.addEventListener('lazyloaded', function (e) {
            if (e.target.parentElement.classList.contains("imagebackground")) {
                e.target.parentElement.style.height = 'auto';
            } else {
                e.target.style.height = 'auto';
            };
        });
    };
};


function addAnalytics() {
    _paq.push(['setCustomUrl', window.location.pathname]);
    _paq.push(['trackPageView']);
};




barba.hooks.enter((data) => {
    setHighlights(data);
    autoOpenSubMenus(data);
});

barba.hooks.after((data) => {
    setHighlights(data);
    isoOpen(data);
    customPlayer();
    addAnalytics();
});
